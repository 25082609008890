import './CategoryPage.css';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MainApi from '../../../utils/MainApi';

export default function Category(data) {
    const [displayCaregory, setDisplayCaregory] = useState(data);
    const [displayUnderCategories, setdisplayUnderCategories] = useState([])

    const { categoryId } = useParams();
    const mainApi = new MainApi();

    console.log(categoryId)
    useEffect(() => {
        if (!displayCaregory?.name) {
            // refetch
            console.log(categoryId)
            mainApi.getCategory(categoryId)
                .then(setDisplayCaregory)
        }
    }, []);

    useEffect(() => {
        mainApi.getCategoryByParentUid(categoryId)
            .then(setdisplayUnderCategories)
    }, []);

    function UnderCategory ({underCategoryData}) {
        return (
            <div>
                <p>{underCategoryData.name}</p>
                <hr className='categoryPageHr'></hr>
            </div>
        )
    }
    console.log(displayUnderCategories)
    return (
        <div className='categoryPage'>
            <div className='categoryPageHead'>
                <div className='categoryPageDescription'>
                    <h1>{displayCaregory.name}</h1>
                    <p>{displayCaregory.description}</p>
                </div>
                <img src={displayCaregory.previewImage} alt={displayCaregory.name} className='categoryPageImg'></img>
            </div>
            <hr className='categoryPageHr'></hr>
            <ul className=''>
                {displayUnderCategories.map((underCategoryData) => (<UnderCategory
                    underCategoryData={underCategoryData}
                />))}
            </ul>
        </div>
    )
}
