import './Cart.css'
import { useState, useEffect } from 'react';

import MainApi from '../../utils/MainApi';

const initialItems = JSON.parse(localStorage.getItem("cart")) || [
    {
        uid: "00000000-0000-0000-0000-000000000001",
        amount: 2,
    },
    {
        uid: "00000000-0000-0000-0000-000000000002",
        amount: 1,
    },
];

export default function Cart() {
    const [items, setItems] = useState([]);
    const mainApi = new MainApi();

    useEffect(() => {

        async function getProductsData() { 
            Promise.all(
                initialItems.map(item => mainApi.getProduct(item.uid))
            )
                .then(results => {
                    console.log(results)
                    const items = results.map(el => {
                        return {
                            ...el,
                            amount: initialItems.find(item => item.uid === el.metadata.uid).amount
                        }
                    }) 
                    console.log(items); // Результаты промисов
                    setItems(items);
                });
        }
       
        getProductsData();

    }, []);

    return (
        <div>
            
        </div>
    )
}
