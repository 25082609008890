import './About.css';

export default function About() {
    return (
        <section id="services">
            <div className="about">
                <h1 className="about__title">О компании</h1>
                <h3 className="about__subtitle">Компания основана в 2004г.</h3>

                <div className="about__text">
                    <p className="about__paragraph">
                        Мы — одна из старейших компаний на рынке производства крупногабаритных пластиковых
                        изделий и оборудования для строительных площадок. Мы так же изготавливаем
                        ротоформовочное оборудование и ротационные формы, комплектуем цеха по производству
                        крупногабаритных пластиковых изделий.
                    </p>

                    <p className="about__paragraph">
                        На собственной производственной базе, при помощи конструкторского отдела разрабатываем
                        новые изделия для себя и заказчиков от идеи до конечного продукта.
                    </p>

                    <p className="about__paragraph">
                        Наш персонал предоставит грамотные консультации по продукции, подберёт необходимую
                        комплектацию и обеспечит своевременную отгрузку и доставку в любой регион в кратчайшие
                        сроки.
                    </p>

                    <h2 className="about__title">Наши преимущества</h2>

                    <ul className="about__list">
                        <li>Прямые поставки с собственного производства позволяют поддерживать самые низкие цены на рынке и предлагать выгодные условия продажи товаров нашим клиентам</li>
                        <li>В любой сезон склады готовой продукции располагают необходимым запасом реализуемых изделий</li>
                        <li>Конструкторский отдел при производстве разрабатывает новые изделия для собственного серийного выпуска, а также под индивидуальный заказ</li>
                        <li>Регулярное участие в тематических выставках позволяет вовремя реагировать на изменения потребительского интереса и быть первыми на реализуемом рынке</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}