import './Documentation.css'

export default function Documentation() {
    return (
        <section id="documentation">
            <div className="documentation">

            </div>
        </section>
    )
}