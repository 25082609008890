/* eslint-disable jsx-a11y/anchor-is-valid */
import './Navigation.css';

export default function Navigation() {
    return (
        <section id="navigation">
            <nav className="navigation">
                <a href="/Catalog" className="navigation__link link">Каталог</a>
                <a href="/Services" className="navigation__link link">Услуги</a>
                <a href="/News" className="navigation__link link">Акции</a>
                <a href="/Delivery" className="navigation__link link">Доставка</a>
            </nav>
        </section>
    )
}