import './Catalog.css';

import { useEffect } from "react";
import { Link } from "react-router-dom";

export default function Catalog({ renderCatalog, categories, setCategoriyPage }) {
    useEffect(() => {
        renderCatalog();
    }, []);

    function ParentCategory({ parentCategory }) {

        return (
            <div className="card" key={parentCategory.metadata.uid}>
                <img src={parentCategory.previewImage} className="categ__image" alt={parentCategory.name} />
                <div className="card__content">
                    <p className="card__title">{parentCategory.name}</p>
                </div>
                <div className='card__container_button'>
                    <Link to={`/Catalog/${parentCategory.metadata.uid}`}>
                        <button onClick={() => setCategoriyPage(parentCategory)} className='card__button'>Подробнее</button>
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <section id="Сatalog" className='catalog'>
            <ul className='catalog__list'>
                {categories.map((parentCategory) => (<ParentCategory
                    parentCategory={parentCategory}
                />))}
            </ul>
        </section>
    )
}
