import "./Main.css";
import Banner from "./Banner/Banner";
import NewsTabs from "./NewsTabs/NewsTabs";
import CatalogTabs from "./Catalog_tabs/Catalog_tabs";


export default function Main({news,renderNews}) {
    return (
        <main className="main">
            <Banner />
            <NewsTabs
                renderNews={renderNews}
                news={news}
            />
            <CatalogTabs />
        </main>
    )
}
