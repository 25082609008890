import { backendUrl } from "./constants";

export default class MainApi {
  constructor() {
    this._baseUrl = backendUrl;
    this._headers = {
      "Content-Type": "application/json",
    };
  }

  _handleResponse(res) {
    if (res.ok) return res.json()
    return Promise.reject("Ошибка: " + res.message);
  }

  //___USERS

  async signIn(username, password) {
    return fetch(`${this._baseUrl}/users/session`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        "username": username,
        "password": password
      })
    }).then(this._handleResponse);
  }

  async signOut() {
    return fetch(`${this._baseUrl}/users/session`, {
      method: "DELETE",
      headers: this._headers,
    }).then(this._handleResponse);
  }

  //___BLOG

  async getPosts(page, size) {
    let params = { page: page, size: size };
    let url = new URL(`${this._baseUrl}/blog/posts`);
    url.search = new URLSearchParams(params);
    //Правильно ли заданы параметры?
    return fetch(url, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  

  async createPost(title, previewImage, description, content) {
    return fetch(`${this._baseUrl}/blog/posts`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        "title": title,
        "previewImage": previewImage,
        "description": description,
        "content": content
      })
    }).then(this._handleResponse);
  }

  async getPost(post_uid) {
    return fetch(`${this._baseUrl}/blog/posts/${post_uid}`, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async updatePost(post_uid, title, previewImage, description, content) {
    return fetch(`${this._baseUrl}/blog/posts/${post_uid}`, {
      method: "PUT",
      headers: this._headers,
      body: JSON.stringify({
        "title": title,
        "previewImage": previewImage,
        "description": description,
        "content": content
      })
    }).then(this._handleResponse);
  }

  async deletePost(post_uid) {
    return fetch(`${this._baseUrl}/blog/posts/${post_uid}`, {
      method: "DELETE",
      headers: this._headers
    }).then(this._handleResponse);
  }

  //___CATALOGUE

  //Catalogue/categories

  async getCategory(category_uid) {
    return fetch(`${this._baseUrl}/catalogue/categories/${category_uid}`, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async updateCategory(category_uid, previewImage, description, name, parentCategoryUid) {
    return fetch(`${this._baseUrl}/catalogue/categories/${category_uid}`, {
      method: "PUT",
      headers: this._headers,
      body: JSON.stringify({
        "previewImage": previewImage,
        "description": description,
        "name": name,
        "parentCategoryUid": parentCategoryUid
      })
    }).then(this._handleResponse);
  }

  async deleteCategory(category_uid) {
    return fetch(`${this._baseUrl}/catalogue/categories/${category_uid}`, {
      method: "DELETE",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async getParentCategory() {
    return fetch(`${this._baseUrl}/catalogue/categories`, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async getCategoryByParentUid(parent_category_uid) {
    let params = { parent_category_uid: parent_category_uid };
    let url = new URL(`${this._baseUrl}/catalogue/categories`);
    url.search = new URLSearchParams(params);
    return fetch(url, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async createCategory(previewImage, description, name, parentCategoryUid) {
    return fetch(`${this._baseUrl}/catalogue/categories`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        "previewImage": previewImage,
        "description": description,
        "name": name,
        "parentCategoryUid": parentCategoryUid
      })
    }).then(this._handleResponse);
  }

  //Catalogue/orders

  async placeOrder(fullname, phone, email, address, products, comment) {
    return fetch(`${this._baseUrl}/catalogue/place-order`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        "customer": {
          "fullname": fullname,
          "phone": phone,
          "email": email,
          "address": address
        },
        "products": {
          "uid": products.uid,
          "amount": products.amount
        },
        "comment": comment
      })
    }).then(this._handleResponse);
  }

  async getOrder(page, size) {
    let params = { page: page, size: size };
    let url = new URL(`${this._baseUrl}/catalogue/orders`);
    url.search = new URLSearchParams(params);
    //Правильно ли заданы параметры?
    return fetch(url, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  //Catalogue/products

  async getProduct(product_uid) {
    return fetch(`${this._baseUrl}/catalogue/products/${product_uid}`, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async updateProduct(product_uid, previewImage, description, name, price, categoryUid) {
    return fetch(`${this._baseUrl}/catalogue/products/${product_uid}`, {
      method: "PUT",
      headers: this._headers,
      body: JSON.stringify({
        "previewImage": previewImage,
        "description": description,
        "name": name,
        "price": price,
        "categoryUid": categoryUid
      })
    }).then(this._handleResponse);
  }

  async deleteProduct(product_uid) {
    return fetch(`${this._baseUrl}/catalogue/products/${product_uid}`, {
      method: "DELETE",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async getProductByParentUid(category_uid, page, size) {
    let params = { category_uid: category_uid, page: page, size: size };
    let url = new URL(`${this._baseUrl}/catalogue/products/by-category/`);
    url.search = new URLSearchParams(params);
    return fetch(url, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async getProducts( page, size) {
    let params = { page: page, size: size };
    let url = new URL(`${this._baseUrl}/catalogue/products/`);
    url.search = new URLSearchParams(params);
    return fetch(url, {
      method: "GET",
      headers: this._headers
    }).then(this._handleResponse);
  }

  async addProduct(previewImage, description, name, price, ProductUid, characteristics) {
    return fetch(`${this._baseUrl}/catalogue/products`, {
      method: "POST",
      headers: this._headers,
      body: JSON.stringify({
        "previewImage": previewImage,
        "description": description,
        "name": name,
        "price": price,
        "CategoryUid": ProductUid,
        "characteristics": characteristics
      })
    }).then(this._handleResponse);
  }
}