import './Admin.css';

export default function Admin({ onLogin, renderPosts, onSubmitPost, onSubmitCategory, onSubmitProduct }) {

    function handleSubmitLogin(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "username": formData.get("username"), "password": formData.get("password")
        };
        onLogin(data);
    }

    function handleSubmitPost(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "title": formData.get("title"),
            "previewImage": formData.get("previewImage"),
            "description": formData.get("description"),
            "content": formData.get("content")
        };
        onSubmitPost(data);
    }

    function handleSubmitCategory(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
        const data = {
            "previewImage": formData.get('previewImage'),
            "description": formData.get('description'),
            "name": formData.get('name'),
            "parentCategoryUid": formData.get('parentCategoryUid')
        };
        onSubmitCategory(data);
    }

    return (
        <section id="admin">
            <div className='admin'>
                <h1>Authentication: </h1>
                <form id="authForm" onSubmit={handleSubmitLogin}>
                    <div>
                        <label>Username: </label>
                        <input type="text" name="username" />
                    </div>
                    <div>
                        <label>Password: </label>
                        <input type="password" name="password" />
                    </div>
                    <button type="submit" value="Authenticate">login</button>
                </form>

                {/*добавить новость */}

                <h1>New Post</h1>
                <form id="createPostForm" onSubmit={handleSubmitPost}>
                    <div>
                        <label>Title: </label>
                        <input type="text" name="title" />
                    </div>
                    <div>
                        <label>Preview image: </label>
                        <input type="text" name="previewImage" />
                    </div>
                    <div>
                        <label>Description: </label>
                        <textarea name="description">
                        </textarea>
                    </div>
                    <div>
                        <label>Content: </label>
                        <textarea name="content">
                        </textarea>
                    </div>
                    <button type="submit" value="Create"> Post</button>
                </form>

                {/*Добавить категорию */}

                <h1>Add Category</h1>
                <form id="addCategory" onSubmit={handleSubmitCategory}>
                    <div>
                        <label>Image: </label>
                        <input type="link" name="previewImage" />
                    </div>
                    <div>
                        <label>Description </label>
                        <input type="text" name="description" />
                    </div>
                    <div>
                        <label>Name </label>
                        <input type="text" name="name" />
                    </div>
                    <div>
                        <label>ParentCategory </label>
                        <input type="id" name="parentCategoryUid" />
                    </div>
                    <button type="submit" value="PostCategory">Post</button>
                </form>
            </div>


        </section>
    )
}