import './Catalog_tabs.css'
import placeholder from '../../../images/Catalog.placeholder.png';

export default function Catalog() {
    return (
        <section id="main__catalog" className='main__catalog'>
                <h2 className='main__title'>Каталог продукции</h2>
                <div className="main__catalog_Tabs">
                    <div className='main__catalog_item'>
                        <img className='main__catalog_img' alt='catalog-1' src={placeholder}></img>
                        <h3 className='main__catalog_item_subtitle'>Placeholder text</h3>
                    </div>

                    <div className='main__catalog_item'>
                        <img className='main__catalog_img' alt='catalog-2' src={placeholder}></img>
                        <h3 className='main__catalog_item_subtitle'>Placeholder text</h3>
                    </div>

                    <div className='main__catalog_item'>
                        <img className='main__catalog_img' alt='catalog-3' src={placeholder}></img>
                        <h3 className='main__catalog_item_subtitle'>Placeholder text</h3>
                    </div>
                </div>
        </section>
    )
}