import './Header.css';
import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
    return (
        <header id="header">
            <div className="header">

                <Link className="header__link-logo link" to="/" />

                <div className="header__links">
                    <Link to="/About" className="link">О Компании</Link>
                    <Link to="/Documentation" className="link">Документация</Link>
                    <Link to="/Contacts" className="link">Контакты</Link>
                    <Link to="/Technologies" className="link">Технология</Link>
                </div>

                <Link to="/Cart" className="header__cart">Корзина</Link>

            </div>
        </header>
    )
}