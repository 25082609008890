import './NewsPanel.css';

import { Link } from "react-router-dom";

export default function NewsPanel({ item, setNewsPage }) {
    return (
        <div className="card" key={item.metadata.uid}>
            <img src={item.previewImage} className="card__image" alt="Новость" />
            <div className="card__content">
                <p className="card__title">{item.title}</p>
                <p className="card__subtitle">{item.description}</p>
            </div>
            <div className='card__container_button'>
                <Link to={`/news/${item.metadata.uid}`}>
                    <button onClick={() => setNewsPage(item)} className='card__button'>Подробнее</button>
                </Link>
            </div>
        </div>
    )
}
