import './Footer.css';
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <section id="footer">
                <div className="footer">
                    <div className='footer__logo'>
                        <Link className="footer__link-logo" to="/" />
                        &#169; 2023 Company Inc.
                    </div>
                    <div className='footer__links'>
                        <Link className="footer__link link" to="/">Стать партненром</Link>
                        <Link className="footer__link link" to="/">На заказ</Link>
                        <Link className="footer__link link" to="/">Контакты</Link>
                        <Link className="footer__link link" to="/">О нас</Link>
                    </div>
                    <div className='footer__contacts'>
                        <a className='footer__number' href="tel:+7 (916) 017-74-08">&#9990; 8 (916) 017-74-08</a>

                        <a className='footer__mail' href="mailto:info@ekoprom.com">info@ekoprom.com</a>
                    </div>
                    <div className='footer__contacts_address'>
                        <p className='footer__point'>&#128205;</p>
                        <div>
                            <b> Адрес</b>
                            <p> Г. Мытищи, <br/> ул. Силикатная, 36, <br/> офис 317</p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}