import './Contacts.css'

export default function Contacts() {
    return (
        <section id="Contacts">
            <div className="contact-wrap">
                <div className="row no-gutters">
                    <div className="col-lg-4 order-lg-12">
                        <div className="infobanner img4 no-link">
                            <div className="infobanner-content">
                                <h3 className="infobanner-title">Контакты</h3>
                                <div className="info-block">
                                    <span className="info-block-title">Наш офис:</span>
                                    <span className="info-block-adres">141013,  Московская область, Мытищи, Силикатная улица, 36, каб. 317</span>
                                </div>
                                <div className="info-block">
                                    <span className="info-block-title">Телефоны:</span>
                                    <span className="info-block-adres">+7 (495) 746-81-40<br /> +7 (985) 783-67-94<br /> +7 (916) 017-74-08</span>
                                </div>
                                <div className="info-block"><span className="info-block-title">Email:</span> <span className="info-block-adres">info@ekoprom.com</span>
                                </div>
                            </div>
                            <span className="infobanner-overlay"></span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}