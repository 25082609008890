import './App.css';
import Header from "../Header/Header";
import Main from "../Main/Main";
import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer";

import Services from "../Services/Services";
import About from "../About/About";
import Documentation from "../Documentation/Documentation";
import Contacts from "../Contacts/Contacts";
import Technologies from "../Technologies/Technologies";
import Catalog from '../Catalog/Catalog';
import Category from '../Catalog/CategoryPage/CategoryPage';
import Admin from '../Admin/Admin';
import News from '../News/News';
import NewsPage from '../News/NewsPage/NewsPage';
import Cart from '../Cart/Cart';

import MainApi from '../../utils/MainApi';



import { Navigate, Route, Routes } from 'react-router-dom';
import { useState } from 'react';

function App() {
  const mainApi = new MainApi();

  const [news, setNews] = useState([])
  const [newsPage, setNewsPage] = useState([])

  const [categories, setCategories] = useState([])
  const [categoriyPage, setCategoriyPage] = useState([])

  // login

  function handleLogin(data) {
    const { username, password } = data;

    mainApi.signIn(username, password)
      .then(res => {
        if (res) {
          console.log(res)
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  // Catalog

  function renderCatalog() {
    mainApi.getParentCategory()
      .then(res => {
        if (res) {
          setCategories(res);
          console.log(res);
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  //render news

  function renderNews(page, size) {

    mainApi.getPosts(page, size)
      .then(res => {
        if (res) {
          setNews(res.posts);
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      });
  }

  //submit category

  function onSubmitCategory(data) {
    const { previewImage, description, name, parentCategoryUid } = data;
    mainApi.createCategory(previewImage, description, name, parentCategoryUid)
      .then(res => {
        if (res) {
          console.log('success')
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  function onSubmitProduct(data) {
    const { previewImage, description, name, price, parentCategoryUid, characteristics } = data;
    mainApi.addProduct(previewImage, description, name, price, parentCategoryUid, characteristics)
      .then(res => {
        if (res) {
          console.log('success')
        }
      })
      .catch((err) => {
        console.log('ERROR:', err)
      })
  }

  return (
    <div className="App">
      <Header />
      <Navigation />
      <Routes>
        <Route path="/*" element={<Navigate to="/" replace />} />

        <Route
          path='/'
          element={
            <Main
              renderNews={renderNews}
              news={news}
            />
          }
        />

        <Route
          path='/Services'
          element={
            <Services />
          }
        />

        <Route
          path='/About'
          element={
            <About />
          }
        />

        <Route
          path='/Documentation'
          element={
            <Documentation />
          }
        />

        <Route
          path='/Contacts'
          element={
            <Contacts />
          }
        />

        <Route
          path='/Technologies'
          element={
            <Technologies />
          }
        />

        <Route
          path='/Cart'
          element={
            <Cart />
          }
        />

        <Route
          path='/Catalog'
          element={
            <Catalog
              renderCatalog={renderCatalog}
              categories={categories}
              categoriyPage={categoriyPage}
              setCategoriyPage={setCategoriyPage}
            />
          }
        />

        <Route
          path='/Catalog/:categoryId'
          element={
            <Category
              data={categoriyPage}
            />
          }
        />

        <Route
          path='/News'
          element={
            <News
              renderNews={renderNews}
              news={news}
              setNewsPage={setNewsPage}
              newsPage={newsPage}
            />
          }
        />

        <Route
          path='/News/:postId'
          element={
            <NewsPage
              data={newsPage}
            />
          }
        />

        <Route
          path='/Dev'
          element={
            <Admin
              onLogin={handleLogin}
              onSubmitCategory={onSubmitCategory}
              onSubmitProduct={onSubmitProduct}
            />
          }
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
