import './NewsTabs.css';

import { useEffect } from "react";

function NewsCard({ image, subtitle }) {
    return (
        <div className='news_item'>
            <img className='news_img' alt='News card' src={image}></img>
            <h3 className='news_item_subtitle'>{subtitle}</h3>
            <button className='item_button'>Подробнее</button>
        </div>
    )
}

export default function News({ renderNews, news }) {
    useEffect(() => {
        renderNews(1,3);
    }, []);


    return (
        <section id="newsTabs">
            <div className='newsSection'>
                <h2 className='title'>Новости</h2>
                <h3 className='subtitle'>Полезные материалы о продукции</h3>
                <div className="news_Tabs">
                    {news.map((item) => (
                        <NewsCard
                            image={item.previewImage}
                            subtitle={item.title}
                        />
                    ))}
                </div>
            </div>
        </section>
    )
}